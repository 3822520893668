import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const routes = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/Dashboard'),
        },
        {
          name: 'Entrar',
          path: 'entrar',
          component: () => import('@/views/Login'),
        },
        {
          name: 'Administração',
          path: 'admin',
          component: () => import('@/views/Admin'),
          meta: {
            authenticated: true,
          },
        },
      ],
    },
  ],
})

routes.beforeEach((to, from, next) => {
  const isAuthRequired = to.matched.some(route => route.meta.authenticated)
  const isAuthenticated = store.getters.isAuthenticated

  if (isAuthRequired) {
    if (isAuthenticated) {
      next()
    } else {
      next('entrar')
    }
  } else {
    next()
  }
})

export default routes
