// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import vuetify from './plugins/vuetify'

// Initialize Firebase
import firebase from 'firebase'
require('firebase/firestore')
const firebaseConfig = {
  apiKey: 'AIzaSyAMH1E-CFWQmQ_wxcaq1t8BKQgXDgObUoQ',
  authDomain: 'covid-pirassununga.firebaseapp.com',
  projectId: 'covid-pirassununga',
  storageBucket: 'covid-pirassununga.appspot.com',
  messagingSenderId: '654293657362',
  appId: '1:654293657362:web:3dbac9287732522ac075cb',
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.prototype.$firebase = firebase

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
