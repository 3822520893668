import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  // TODO Configurar para persistir os dados somente durante a sessão
  key: 'covid-pirassununga',
  storage: window.localStorage,
})

export default new Vuex.Store({
  state: {
    authenticated: false,
  },
  mutations: {
    authenticate (state) {
      state.authenticated = true
    },
  },
  actions: {
    authenticate (context) {
      context.commit('authenticate')
    },
  },
  getters: {
    isAuthenticated: state => {
      return state.authenticated === true
    },
  },
  plugins: [
    vuexLocalStorage.plugin,
  ],
})
